// Main (1st level) navigation

.nav-main {
	.wp-nav-wrapper {
		display: flex;
		flex-wrap: wrap;
	}

	a {
		padding: 12px 8px;
	}

	li {
		display: flex;
		align-items: center;
		position: relative;
	}

	li:not(:last-child) {
		margin-right: 1px;
	}

	[aria-current] {
		color: $theme-color-2;
	}
}

@include media-breakpoint-up(md) {
	.nav-main {
		a {
			padding: 18px 12px;
			font-weight: 800;
		}
	}
}

@include media-breakpoint-up(xl) {
	.nav-main {
		li {
			text-align: center;

			.sub-menu {
				left: -15%;
			}

			&:first-child .sub-menu {
				left: 0%;
			}

			&:last-child .sub-menu {
				left: -30%;
			}
		}

		.wp-nav-wrapper {
			flex-wrap: nowrap;
		}
	}
}

.toggle-submenu {
	background: transparent;
	width: 4rem;
	min-width: 4rem;
	height: 4rem;
	padding: 1rem;

	&:hover, &:focus, &:active {
		outline: 2px dotted $theme-color-2;
	}

	svg {
		pointer-events: none;
		width: 100%;
		height: 100%;
		transition: .2s ease-in-out;
	}

	&.opened {
		svg {
			transform: rotate(180deg);
		}
	}
}

.nav-main .sub-menu {
	display: none;
	box-shadow: 0 1rem 1rem rgba($black, .6);
	position: absolute;
	top: 100%;
	z-index: 1;
	background: $white;
	width: 100%;

	@include media-breakpoint-up(xl) {
		width: 130%;
	}

	li a {
		width: 100%;
		text-align: left;
	}

	&--visible {
		display: block;
	}
}
