// == PWD WP Sass Framework ==
// Theme header styles
// Template: templates/header.php

.header-theme__logo,
.header-theme__links:not(:last-child),
.header-theme .some-links {
	margin-bottom: $base-margin * 0.5;
}

.header-theme {
	&__mobile {
		justify-content: space-around;

		a {
			margin: 1rem;
		}
	}

	&__logo {
		margin-top: $base-margin;
	}

	&__logo-wrap {
		display: inline-block;
		max-width: 126px;
	}

	&__logo-image {
		max-width: 100%;
		height: auto;
	}

	&__link {
		margin-bottom: 5px;
	}

	&__link--text {
		padding-right: 9px;
		padding-left: 9px;
		border-right: 2px solid $theme-color-5;
		margin-right: 0;

		&:first-of-type {
			border-left: 2px solid $theme-color-5;
		}
	}

	&__links {
		margin-left: $grid-gutter-width * 0.5 * -1;
		margin-right: $grid-gutter-width * 0.5 * -1;
		padding: 5px $grid-gutter-width * 0.5 0 $grid-gutter-width * 0.5;
		align-items: center;
		font-weight: 500;
		background-color: #F1F1F1;
	}

	.some-links {
		justify-content: flex-end;
	}
}

.header-theme__logo-link {
	position: relative;
	max-width: 100%;

	&:after {
		position: absolute;
		left: 0;
		bottom: -6px;
		width: 100%;
		height: 4px;
		content: "";
		opacity: 0;
		background-color: $theme-color-3;
		transition: opacity .35s;
	}

	&:hover:after {
		opacity: 1;
		transition: opacity .15s;
	}
}

@include media-breakpoint-up(sm) {
	.header-theme {
		&__right {
			text-align: right;
			flex-grow: 2;
		}
	}
}

@include media-breakpoint-up(md) {
	.header-theme {
		&__links {
			padding-left: 25px;
			justify-content: flex-end;
			border-bottom-left-radius: 15px;
			display: inline-flex;
			margin-left: 0;
		}
	}
}

.flex--sm-column{
	flex-direction: column;
	align-items: flex-end;
	gap: 0.5rem;
	@include media-breakpoint-up(sm) {
		flex-direction: row;
		align-items: center;
	}
}

#vic-desktop{
	margin-bottom: 5px;
}

.vic-container {
	z-index: 100000;
}

@include media-breakpoint-down(md) {
	#responsive-menu-pro-container {
		z-index: 100000 !important;
	}
}
