/* ==========================================================================
  Global - Fonts
========================================================================== */

/* Nunito Sans Regular */
@font-face {
	font-family: "Nunito Sans";
	font-style: regular;
	font-weight: 400;
	src: local("Nunito Sans Regular"), local("NunitoSans-Regular"),
		url("../fonts/NunitoSans-Regular.ttf") format("truetype");
}

/* Nunito Sans ExtraBold */
@font-face {
	font-family: "Nunito Sans";
	font-style: regular;
	font-weight: 600;
	src: local("Nunito Sans ExtraBold"), local("NunitoSans-ExtraBold"),
		url("../fonts/NunitoSans-ExtraBold.ttf") format("truetype");
}

/* Nunito Sans SemiBold */
@font-face {
	font-family: "Nunito Sans";
	font-style: regular;
	font-weight: 500;
	src: local("Nunito Sans SemiBold"), local("NunitoSans-SemiBold"),
		url("../fonts/NunitoSans-SemiBold.ttf") format("truetype");
}

/* Nunito Sans Black */
@font-face {
	font-family: "Nunito Sans";
	font-style: regular;
	font-weight: 800;
	src: local("Nunito Sans Black"), local("NunitoSans-Black"),
		url("../fonts/NunitoSans-Black.ttf") format("truetype");
}


/* Nunito Sans Italic */
@font-face {
	font-family: "Nunito Sans";
	font-style: italic;
	font-weight: 400;
	src: local("Nunito Sans Italic"), local("NunitoSans-Italic"),
		url("../fonts/NunitoSans-Italic.ttf") format("truetype");
}

/* Nunito Sans ExtraBoldItalic */
@font-face {
	font-family: "Nunito Sans";
	font-style: italic;
	font-weight: 600;
	src: local("Nunito Sans ExtraBoldItalic"), local("NunitoSans-ExtraBoldItalic"),
		url("../fonts/NunitoSans-ExtraBoldItalic.ttf") format("truetype");
}

/* Nunito Sans SemiBoldItalic */
@font-face {
	font-family: "Nunito Sans";
	font-style: italic;
	font-weight: 500;
	src: local("Nunito Sans SemiBoldItalic"), local("NunitoSans-SemiBoldItalic"),
		url("../fonts/NunitoSans-SemiBoldItalic.ttf") format("truetype");
}
