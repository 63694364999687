// == PWD WP Sass Framework ==
// Social media link styles
// Template: 'templates/some-links.php

.some-links {
	&__link {
		&:not(:last-child) {
			margin-right: 10px;
		}
	}
}
