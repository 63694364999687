// == PWD WP Sass Framework ==
// Accessibility tools and related styles
// Library: lib/accessibility.php

@use "sass:math";

.font-switch__label,
.font-switch__link {
	display: inline-block;
}

.contrast-switch__link,
.font-switch__link {
	position: relative;
	padding-bottom: 2px;
	text-decoration: none;

	&::after {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 1px;
		content: "";
		background-color: $theme-color-1;
	}

	&:hover::after {
		background-color: $base-link-effect-color;
	}
}

.contrast-switch__link {
	top: -2px;
	margin-left: 5px;
	vertical-align: middle;

	&:hover .contrast-switch__icon path {
		transition: fill .15s;
	}
}

.contrast-switch__icon {
	display: block;

	path {
		fill: currentColor;
		transition: fill .35s;
	}
}

.contrast-switch__link:hover .contrast-switch__icon {
	path {
		fill: $base-link-effect-color;
	}
}

.font-switch {
	align-items: center;
	margin-right: 9px;

	&__label {
		display: flex;
		margin-right: 5px;
	}

	&__link {
		align-items: flex-end;
		width: 1em;
		text-align: center;
		vertical-align: baseline;
		line-height: 1;

		&:not(:last-child) {
			margin-right: 5px;
		}
	}

	// Disable pointer events (clicking, dragging, hovering, etc.) on current option

	[aria-current] {
		pointer-events: none;
	}
}

[data-tay-font-size-value="1"] {
	font-size: 1.2em;
}

[data-tay-font-size-value="2"] {
	font-size: 1.3em;
}

.font-size--medium {
	$font-size: percentage(math.div($base-reset-target-size + 1, $base-browser-font-size));
	font-size: $font-size;
	-webkit-text-size-adjust: $font-size;
	-ms-text-size-adjust: $font-size;
}

.font-size--large {
	$font-size: percentage(math.div($base-reset-target-size + 2, $base-browser-font-size));
	font-size: $font-size;
	-webkit-text-size-adjust: $font-size;
	-ms-text-size-adjust: $font-size;
}
