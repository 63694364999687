@mixin content-links {
	a,
	a:active {
		color: $base-link-color;
	}

	a {
		font-weight: 600;
		text-decoration: underline;
		background-color: transparent; // 1
		-webkit-text-decoration-skip: objects; // 2

		&:hover {
			text-decoration-color: $base-link-effect-color;
		}

		&:focus {
			outline: 2px dotted $base-link-effect-color;
			outline-offset: 1px;
		}
	}

	.content a {
		&:hover {
			color: $base-link-effect-color;
		}
	}
}
