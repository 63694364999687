// Critical, above the fold styles. Loaded inline in
// theme's <head> element.

@import "common/variables";
@import "common/tools";
@import "components/normalize";
@import "common/fonts";

// Asynchronous web fonts loading

.webfonts--loaded {
	font-family: $font-family-1;

	.font-family--2 {
		font-family: $font-family-2;
		font-weight: 600;
	}
}

h1, .h1,
h2, .h2 {
	font-weight: 400;
}

// Visually hide element/text but make it accessible to screen readers
// https://allyjs.io/tutorials/hiding-elements.html

.screen-reader-text:not(:focus):not(:active) {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	white-space: nowrap;
	clip-path: inset(100%);
	clip: rect(0 0 0 0);
	overflow: hidden;
}

.cf {
	&:before {
		display: table;
		content: "";
	}

	&:after {
		display: table;
		content: "";
		clear: both;
	}
}

nav ul,
.list--unstyled,
.lists--unstyled ul,
.list--row {
	margin-bottom: 0;
	padding-left: 0;
	list-style-type: none;

	li {
		margin-top: 0;
	}
}

nav ul,
.list--unstyled,
.lists--unstyled ul {
	margin-left: 0;
}

.list--horizontal li {
	display: inline-block;
}

.no-margin {
	margin: 0;
}

// Common / flexbox

.flex {
	display: flex;

	&--justify-center {
		justify-content: center;
	}

	&--align-self-center {
		align-self: center;
	}
}

.flex--wrap {
	flex-wrap: wrap;
}

/*
.flex--align-self-start {
	align-self: flex-start;
}
*/
.flex--align-items-center {
	align-items: center;
}

// Skip to main content element & link

.skip-main {
	&:focus {
		outline: 0;

		span {
			outline: 1px dotted $base-link-effect-color;
		}
	}
}

#main {
	outline: 0;
}

// Texts

.text {
	&--medium {
		@include pwd-fontsize(17);
	}

	&--small {
		@include pwd-fontsize(15);
	}

	&--uppercase {
		text-transform: uppercase;
	}
}

// Borders

.border-bottom {
	border-color: #000000;
	border-width: 1px;
	border-style: none;
	border-bottom-style: solid;
}

// Links

.link--icon {
	min-width: 44px;
	min-height: 44px;
}

.link-effect-1,
.link-effect-2,
.links-effect-2 a {
	text-decoration: none;
}

.link-effect-1 {
	position: relative;
	padding: 4px;

	&::before {
		display: block;
		position: absolute;
		top: 2px;
		bottom: 2px;
		left: 2px;
		right: 2px;
		border: 2px solid rgba($base-link-effect-color, 0);
		content: "";
		transition: border-color .35s;
	}

	&:hover::before {
		border-color: rgba($base-link-effect-color, 1);
		transition: border-color .15s;
	}

	&:focus {
		outline-offset: 0;
	}
}

.link-effect-2,
.links-effect-2 a {
	display: block;
	position: relative;
	padding-bottom: 7px;

	&::before {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 5px;
		content: "";
		background-color: $theme-color-5;
		opacity: 0;
		transition: opacity .35s;
	}

	&:hover::before {
		transition: opacity .15s;
	}
}

.link-effect-2:hover,
.links-effect-2 a:hover,
.links-effect-2 a[aria-current] {
	&::before {
		opacity: 1;
	}
}

//Colors

.color--blue {
	color: $theme-color-1;
}

//icons

.icon--contacts {
	path {
		fill: #000000;
	}
}

.icon--search-form {
	path {
		fill: $white;
	}
}

@media screen {
	.theme-bg-1 {
		background-color: $theme-color-1;
	}

	// Borders

	.border-bottom {
		border-color: $theme-color-5;
	}
}

@media only print, screen and (min-width: map-get($grid-breakpoints, sm)) {
	// Common / flexbox

	.flex-sm {
		display: flex;

		&--grow-1 {
			flex-grow: 1;
		}

		&--align-self-center {
			align-self: center;
		}

		&--justify-end {
			justify-content: flex-end;
		}
	}
}

@import "components/header";
@import "components/nav-main";
@import "components/some-links";
@import "components/accessibility-tools";

